import {
  Home,
  TournamentRedirect,
  TermsAndConditions,
  PrivacyPolicy,
  ZendeskWidget,
} from './pages'

export const PUBLIC_ROUTES = [
  {
    path: '/',
    Component: Home,
  },
  {
    path: '/redirects/tournament/:id',
    Component: TournamentRedirect,
  },
  { path: '/terms-and-conditions', Component: TermsAndConditions },
  { path: '/privacy-policy', Component: PrivacyPolicy },
  { path: '/support', Component: ZendeskWidget },
]
